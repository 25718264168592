import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { DIRECT_INVOICE } from '@utils/constants/direct-invoice.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const directInvoiceApi = createApi({
    reducerPath: 'directInvoiceApi',
    tagTypes: ['DirectInvoice'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllDirectInvoices: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                direct_sales_invoice_code?: string;
            }
        >({
            query: ({ page = 1, limit = 10, direct_sales_invoice_code }) => {
                const queryParams = {
                    url: DIRECT_INVOICE.GET_ALL,
                    params: { page, limit },
                };

                if (direct_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.direct_sales_invoice_code =
                        direct_sales_invoice_code;
                }

                return queryParams;
            },
        }),
        getDirectInvoice: builder.query<
            any,
            { direct_sales_invoice_id: string | number }
        >({
            query: ({ direct_sales_invoice_id }) => {
                return `${DIRECT_INVOICE.GET_ONE}${direct_sales_invoice_id}`;
            },
        }),
        createDirectInvoice: builder.mutation({
            query: (body) => ({
                url: DIRECT_INVOICE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateDirectInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${DIRECT_INVOICE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteDirectInvoice: builder.mutation({
            query: (id) => ({
                url: `${DIRECT_INVOICE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllDirectInvoicesQuery,
    useGetDirectInvoiceQuery,
    useLazyGetDirectInvoiceQuery,
    useCreateDirectInvoiceMutation,
    useUpdateDirectInvoiceMutation,
    useDeleteDirectInvoiceMutation,
} = directInvoiceApi;
