import { TGoodsDelivery } from '@helpers/validation-schemas/goods-request/goods-delivery-note.schema';
import { useGetGoodsDeliveryQuery } from '@states/goods-request/delivery-note.api';
import { TGetOneSuccessResponse } from '@type-defs/general/TGetOneSuccessResponse';

const useGetOneGoodsDelivery = (id: string | number) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch
    } = useGetGoodsDeliveryQuery({
        goods_delivery_id : id!
    });

    const goodsDeliveryResponse =
        rawData as any as TGetOneSuccessResponse<TGoodsDelivery>;

    return {
        goodsDeliveryData: goodsDeliveryResponse?.data,
        goodsDeliveryMessage: goodsDeliveryResponse?.message,
        goodsDeliverySuccess: goodsDeliveryResponse?.success,
        goodsDeliveryError: error,
        goodsDeliveryLoading: isLoading,
        goodsDeliveryRefetch: refetch,
    };
};

export default useGetOneGoodsDelivery;
