import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { SALE_TARGET } from "../../utils/constants/sale-target.constants";

export const saleTargetApi = createApi({
  reducerPath: "saleTargetApi",
  tagTypes: ["SaleTarget"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllSaleTargets: builder.query<[], {
      page: number; limit: number, sales_target_type_id?: number | string, from_date?: string,
      to_date?: string
    }>({
      query: ({ page = 1, limit = 10, sales_target_type_id = 1, from_date, to_date }) => {
        const params = {
          page,
          limit,
          sales_target_type_id,
          ...(from_date && to_date ? { from_date, to_date } : {}),
        };

        return {
          url: SALE_TARGET.GET_ALL,
          params,
        };
      }
    }),
    getSaleTarget: builder.query<any, { sales_target_id: string | number }>({
      query: ({ sales_target_id }) => {
        return `${SALE_TARGET.GET_ONE}${sales_target_id}`;
      },
    }),
    createSaleTarget: builder.mutation({
      query: (body) => ({
        url: SALE_TARGET.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateSaleTarget: builder.mutation({
      query: ({ id, body }) => ({
        url: `${SALE_TARGET.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteSaleTarget: builder.mutation({
      query: (id) => ({
        url: `${SALE_TARGET.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor:0
});

export const {
  useGetAllSaleTargetsQuery,
  useCreateSaleTargetMutation,
  useUpdateSaleTargetMutation,
  useDeleteSaleTargetMutation,
  useGetSaleTargetQuery,
} = saleTargetApi;
