import React from 'react';
import '../../assets/styles/leaving.css';

export const LeavingSpinner: React.FC = () => {
    return (
        <div id="overlay">
            <div className="leaving"></div>
        </div>
    );
};
