import React from 'react';
import { useController, Control } from 'react-hook-form';

interface CustomRadioGroupProps {
    name: string;
    options: { label: string; value: string | number }[];
    control: Control;
    label?: string;
    errors: any;
    required?: boolean;
    colSpan?: string | number;
    classNames?: string;
    disabled?:boolean;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
    name,
    options,
    control,
    label,
    colSpan,
    classNames,
    errors,
    disabled,
    required = false,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        // Set default value to the value of the first option in the list
        defaultValue: options[0]?.value || '',
        rules: { required: required && 'This field is required' },
    });

    return (
        <div
            style={{
                gridColumn: colSpan !== 6 ? `auto / span ${colSpan || '2'}` : '1 / -1',
            }}
            className={`flex flex-col p-2 ${classNames}`}
        >
            {label && <label className="mb-2 text-gray-700"
                    style={{ textTransform: 'capitalize' }}
                    htmlFor="name">{label}</label>}
            <div className="flex gap-10 mt-6">
                {options.map((option) => (
                    <label key={option.value} className="flex items-center">
                        <input
                            disabled={disabled}
                            type="radio"
                            value={option.value}
                            checked={value == option.value}
                            onChange={onChange}
                            className="mr-2"
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            {errors[name] && (
                <div className="text-red-500 mt-2">{errors[name]?.message}</div>
            )}
        </div>
    );
};

export default CustomRadioGroup;
