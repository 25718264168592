import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_SALES_REQUEST } from '@utils/constants/trip/trip-sales-request.constants';

export const tripSalesRequestApi = createApi({
    reducerPath: 'tripSalesRequestApi',
    tagTypes: ['TripSalesRequest'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTripSalesRequests: builder.query<
            [],
            { page?: number; limit?: number; product_tag_name?: string }
        >({
            query: ({ page = 1, limit = 10, product_tag_name }) => {
                const queryParams = {
                    url: TRIP_SALES_REQUEST.GET_ALL,
                    params: { page, limit },
                };

                if (product_tag_name) {
                    //@ts-ignore
                    queryParams.params.product_tag_name = product_tag_name;
                }

                return queryParams;
            },
        }),
        getTripSalesRequest: builder.query<any, { id: string | number }>({
            query: ({ id }) => TRIP_SALES_REQUEST.GET_ONE + id,
        }),
        getDetailTripSalesRequest: builder.query<any, { id: string | number }>({
            query: ({ id }) => TRIP_SALES_REQUEST.DETAIL_ONE + id,
        }),
        createTripSalesRequest: builder.mutation({
            query: (body) => ({
                url: TRIP_SALES_REQUEST.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateTripSalesRequest: builder.mutation({
            query: ({ id, body }) => ({
                url: `${TRIP_SALES_REQUEST.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteTripSalesRequest: builder.mutation({
            query: (id) => ({
                url: `${TRIP_SALES_REQUEST.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllTripSalesRequestsQuery,
    useCreateTripSalesRequestMutation,
    useLazyGetTripSalesRequestQuery,
    useLazyGetDetailTripSalesRequestQuery,
    useGetTripSalesRequestQuery,
    useUpdateTripSalesRequestMutation,
    useDeleteTripSalesRequestMutation,
} = tripSalesRequestApi;
