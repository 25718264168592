export const SECONDARY_SALES_RETURN = {
    BASE_PATH: '/secondary-sales-return/sales-return/',
    BASE_PATH_MAKE_PAYMENT: '/secondary-sales-return/make-payment/',
    MAKE_PAYMENT_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Sales Return',
    LIST_PAGE_TITLE: 'Sales Return List',
    EDIT_PAGE_TITLE: 'Edit Sales Return',
    GET_ALL: 'secondary-sale-return',
    GET_ALL_MAKE_PAYMENTS: 'secondary-sale-return/payment',
    GET_ONE: 'secondary-sale-return/edit/',
    CREATE: 'secondary-sale-return/create',
    UPDATE: 'secondary-sale-return/update/',
    DELETE: 'secondary-sale-return/delete/',
    DELETE_MAKE_PAYMENT: 'secondary-sale-return/payment/delete/',
    MAKE_PAYMENT: 'secondary-sale-return/payment/update/',
    ADD_NEW_TITLE: 'Add New Sales Return',
};
