import CustomForm from "@components/form/CustomForm";
import CustomSearchModalSelectField from "@components/form/CustomSearchModalSelectField";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner"
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from "@components/layouts/PageWrapper"
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";
import { TRIP_MANAGEMENT } from '@utils/constants/trip/trip.constants';
import { useCallback, useEffect, useMemo, useState } from "react";
import { removeKeys } from '@utils/general/removeKeys';
import { useCreateTripMutation } from '@states/trip/trip.api';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { Toast } from '@helpers/popups/Toast';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '@states/store';
import { LeavingSpinner } from "@components/layouts/LeavingSpinner";
import { tripFields } from "@helpers/fields/trip/trip.fields";
import { tripSchema } from '@helpers/validation-schemas/trip/trip.schema';
import {
    addRegions,
    addTripDetails,
    addWays,
    addAllocations,
} from '@states/common/common.slice';
import {
    addCities,
    addStates,
    addTownships,
} from '@states/common/common.slice';
import { useGetAllStatesQuery } from "@states/common/common.api";
import useRegionEffect from "@hooks/distribution-region/useRegionEffect";
import TripDetailListTable from "@components/extra/trip/TripDetailListTable";
import RegionDetailTable from "@components/extra/distribution-region/RegionDetailTable";
import {useErrorHandling} from "@hooks/useErrorHandling";
import {castTypeArr} from "@utils/general/cast-type";
import {TFailResponse} from "@type-defs/general/TFailResponse";


const  TripManagementAddPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [leaving, setLeaving] = useState(false);
    const {
        cities,
        townships,
        // regionDetail,
        isLoading: regionLoading,
    } = useRegionEffect();


    const { warehouseData } = useGetAllWarehouses({limit:1000});
    const { data: stateData } = useGetAllStatesQuery();


    const [createTrip, { error: createError, isSuccess, isLoading }] =
    useCreateTripMutation();

    const { regions, ways, tripDetails ,warehouses,
        states: storedStates,
        states,
        cities: storedCities,
        townships: storedTownships,
        regionDetails: regionDetail,
    } = useSelector(
        (state: RootState) => state.common
    );
   console.log("warehouses selector",warehouses)
    const fields = useMemo(()=> {
        return tripFields({
            data: {
                warehouses: warehouseData,
                states: stateData || [],
                cities: cities || [],
                townships: townships || [],
            },
            selectActions : {
                warehouseSelectionAction : addAllocations,
                stateSelectionAction: addStates,
                citySelectionAction: addCities,
                townshipSelectionAction: addTownships,
            },
            disabled: {
                cityDisabled: storedStates.length <= 0,
                townshipDisabled: storedCities.length <= 0,
            },
            selectedIds: {
                warehouseSelectedIds: warehouses.map((wh:any) => wh.warehouse_id),
                divisionSelectedIds: states?.map((s: any) => s.state_id),
                citySelectedIds: storedCities?.map((c: any) => c.city_id),
                townshipSelectedIds: storedTownships?.map((t: any) => t.township_id),
            },
            values:{
                warehousesValue : ''
            }
            
        })
    },[
        stateData,
        states,
        cities,
        townships,
        warehouseData,
        warehouses,
        storedStates,
        storedCities,
        storedTownships
    ]) 

    console.log('cities',cities)
    console.log('storedCities',storedCities)
    useErrorHandling(...castTypeArr<TFailResponse>([createError]));

    useEffect(()=>{
        if(!createError){
            dispatch(
                addAllocations(
                    []
                )
            );
            dispatch(
                addStates(
                   []
                )
            );
            dispatch(
                addCities(
                    []
                )
            );
            dispatch(
                addTownships(
                    []
                )
            );
        }
    },[createError,dispatch])
    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                const rest = removeKeys(reqBody, 'region_id', 'way_id','state_id','township_id','city_id','warehouse_id');
                console.log("warehouses====>",warehouses)

                const res = await createTrip({
                    ...rest,
                    warehouses,
                    trip_details: regionDetail.map((rd) => ({
                        division_id: rd.state_id,
                        city_id: rd.city_id,
                        township_id: rd.township_id,
                    })),

                });

                const successData = 'data' in res ? res.data : null;
                const { success, message } = successData as TSuccessResponse;

                if (success || isSuccess) {
                    setLeaving(true);

                    await Toast.fire({
                        title: message,
                        icon: 'success',
                    });

                    navigate(TRIP_MANAGEMENT.BASE_PATH, {
                        state: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                console.log('Error', error);
            }
        },
        [createTrip, isSuccess, Toast, navigate,regionDetail,warehouses]
    );

    return (
        <PageWrapper>
            {leaving && <LeavingSpinner />}
            <CustomForm
                title={TRIP_MANAGEMENT.ADD_NEW_TITLE}
                schema={tripSchema}
                fields={fields}
                asyncDispatcher={asyncDispatcher}
                customComponent={
                    <TripDetailListTable regionDetail={regionDetail} />
                }

                // customComponent={
                //     <RegionDetailTable regionDetail={regionDetail} />
                // }
            />
        </PageWrapper>
    )
}

export default TripManagementAddPage;
