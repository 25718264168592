import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PRIMARY_SALES_RETURN } from '@utils/constants/primary-sales-return/sales-return.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const primarySalesReturnApi = createApi({
    reducerPath: 'primarySalesReturnApi',
    tagTypes: ['PrimarySalesReturn'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllPrimarySaleReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                primary_sales_invoice_code?: string;
                primary_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                primary_sales_invoice_code,
                primary_sales_return_code,
            }) => {
                const queryParams = {
                    url: PRIMARY_SALES_RETURN.GET_ALL,
                    params: { page, limit },
                };

                if (customer_first_name) {
                    //@ts-ignore
                    queryParams.params.customer_first_name =
                        customer_first_name;
                }

                if (primary_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_invoice_code =
                        primary_sales_invoice_code;
                }

                if (primary_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_return_code =
                        primary_sales_return_code;
                }

                return queryParams;
            },
        }),
        getAllPrimaryMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                primary_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,

                primary_sales_return_code,
            }) => {
                const queryParams = {
                    url: PRIMARY_SALES_RETURN.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit },
                };

                if (primary_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_return_code =
                        primary_sales_return_code;
                }

                return queryParams;
            },
        }),
        getPrimarySaleReturn: builder.query<
            any,
            { primary_sales_return_id: string | number }
        >({
            query: ({ primary_sales_return_id }) =>
                `${PRIMARY_SALES_RETURN.GET_ONE}${primary_sales_return_id}`,
        }),
        createPrimarySaleReturn: builder.mutation({
            query: (body) => ({
                url: PRIMARY_SALES_RETURN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePrimarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRIMARY_SALES_RETURN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        createPrimaryMakePayment: builder.mutation({
            query: ({ primary_sales_return_id, body }) => ({
                url: `${PRIMARY_SALES_RETURN.MAKE_PAYMENT}${primary_sales_return_id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePrimarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRIMARY_SALES_RETURN.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        deletePrimaryMakePayment: builder.mutation({
            query: ({ primary_sales_return_payment_made_id, body }) => ({
                url: `${PRIMARY_SALES_RETURN.DELETE_MAKE_PAYMENT}${primary_sales_return_payment_made_id}`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllPrimarySaleReturnsQuery,
    useGetPrimarySaleReturnQuery,
    useCreatePrimarySaleReturnMutation,
    useUpdatePrimarySaleReturnMutation,
    useDeletePrimarySaleReturnMutation,
    useCreatePrimaryMakePaymentMutation,
    useGetAllPrimaryMakePaymentsQuery,
    useDeletePrimaryMakePaymentMutation,
} = primarySalesReturnApi;
