import DeleteButton from '@components/buttons/DeleteButton';
import EditButton from '@components/buttons/EditButton';
import { thousandSeperator } from '@utils/general/digit-separators';
import OutlinedStatus from '@components/status/OutlinedStatus';
import { formatDate } from '@utils/general/format-date';

export const consignmenttColumns = (
    editHandler: (id: string | number, status: string | number) => void,
    deleteHandler: () => void
) => [
    {
        name: 'No',
        selector: (row: any) => (!isNaN(row.no) ? row.no + 1 : row.no),
        width: '10ch',
    },
    {
        name: 'Consignment ID',
        selector: (row: any) => (
            <div className="italic font-semibold text-primary-dark">
                {row.consignment_code}
            </div>
        ),
        // sortable: true,
        // wrap: true,
    },
    {
        name: 'Sales Date',
        selector: (row: any) => formatDate(new Date(row.sales_date)),
        // sortable: true,
        wrap: true,
    },
    {
        name: 'Customer Name',
        selector: (row: any) =>
            //@ts-ignore
            row.no !== 'Totals:' && (
                <div className="font-semibold text-primary-dark">
                    {row.customer_name || row.customer_first_name}
                </div>
            ),
        // sortable: true,
        wrap: true,
    },
    {
        name: 'Business Unit Name',
        selector: (row: any) =>
            row.business_unit_name ||
            //@ts-ignore
            row.no !== 'Totals:' || <div className="italic text-red-600"></div>,
        // sortable: true,
        wrap: true,
    },
    {
        name: 'Payment Type',
        selector: (row: any) => row.payment_type_name,
        // sortable: true,
        // wrap: true,
    },
    {
        name: 'Status',
        selector: (row: any) =>
            //@ts-ignore
            row.no !== 'Totals:' && (
                <OutlinedStatus
                    width="w-[17ch]"
                    color={
                        row.status === 1
                            ? 'blue'
                            : row.status === 3 || row.status === 2
                            ? 'green'
                            : row.status === 4
                            ? 'red'
                            : 'blue'
                    }
                    title={
                        row.status === 1
                            ? 'Open'
                            : row.status === 2
                            ? 'Created Invoice'
                            : row.status === 3
                            ? 'Approve & Open'
                            : row.status === 4
                            ? 'Rejected'
                            : 'Closed'
                    }
                />
            ),
        grow: 2,
        // sortable: true,
    },
    {
        name: 'Grand Total Amount',
        selector: (row: any) => 
             //@ts-ignore
            thousandSeperator(Number(row.grand_total_amount) || 0),
            // thousandSeperator(row.grand_total_amount || 0),
        // sortable: true,
    },
    {
        name: 'Action',
        right: 'true',
        selector: (row: any) =>
            //@ts-ignore
            !row.noAction ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {/* <EditButton
                        id={row.consignment_id}
                        actionHandler={() =>
                            editHandler(row.consignment_id, row.status)
                        }
                    /> */}
                    <EditButton
                        disabled={row.status == 2}
                        id={row.consignment_id}
                        actionHandler={() =>
                            editHandler(row.consignment_id, row.status)
                        }
                    />
                    <DeleteButton
                        disabled={row.status == 2}
                        id={row.consignment_id}
                        actionHandler={deleteHandler}
                    />
                </div>
            ) : null,
    },
];
