import PageWrapper from '@components/layouts/PageWrapper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { Toast } from '@helpers/popups/Toast';
import { LoadingSpinner } from '@components/layouts/LoadingSpinner';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useGetAllWarehouses from '@hooks/warehouse-and-inventory/useGetAllWarehouses';

import { z } from 'zod';
import BackButton from '@components/buttons/BackButton';
import CustomSearchModalChooseField from '@components/form/CustomSearchModalChooseField';
import CustomTextAreaField from '@components/form/CustomTextAreaField';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import {
    addFormValues,
    addProductDetail,
    removeProductDetail,
    replaceProductDetail,
    resetFormValues,
    updateProductDetail,
} from '@states/common/common.slice';
import CustomButton from '@components/buttons/CustomButton';
import CustomDateField from '@components/form/CustomDateField';
import CustomSelectField from '@components/form/CustomSelectField';
import {
    consignmentproductDetailColumns,
    consignmentproductDetailColumnNames,
} from '@helpers/columns/consignment/consignment-detail.columns';
import CustomInputField from '@components/form/CustomInputField';
import { thousandSeperator } from '@utils/general/digit-separators';
import { CONSIGNMENT } from '@utils/constants/consignment/consignment.constants';
import TableSkeleton from '@components/layouts/TableSkeleton';
import TableEmpty from '@components/layouts/TableEmpty';

import {
    useGetConsignmentContractCustomerQuery,
    useGetConsignmentContractProductDetailQuery,
    useLazyGetConsignmentContractProductDetailQuery,
    useCreateConsignmentMutation,
} from '@states/consignment/consignment.api';
import { consignmentSchema } from '@helpers/validation-schemas/consignment/consignment.scehma';
import { useGetAllPaymentTermsQuery } from '@states/common/common.api';
import { formatDBDate } from '@utils/general/format-db-date';
import { formatUTCDate } from '@utils/general/format-utc-date';

type FormFields = z.infer<typeof consignmentSchema>;

function truncateDecimal(input: any, decimalPlaces: number = 2): number {
    let number = parseFloat(input);
    if (isNaN(number)) {
      return 0;
    }
  
    if (Number.isInteger(number)) {
      return number;
    }
    return parseFloat(number.toFixed(decimalPlaces));
  }
const ConsignmentAddPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [businessUnits, setBusinessUnits] = useState<any>([]);

    const { formValues } = useSelector((state: RootState) => state.common);

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        watch,
        setValue,
        control,
        formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
        reset,
    } = useForm<FormFields>({
        resolver: zodResolver(consignmentSchema),
    }); 

    console.log('erros',errors['customer_id'] )
   
    const { data: customerData } = useGetConsignmentContractCustomerQuery({
        page: 1,
        limit: 1000,
    });
    const { data: paymentTermData } = useGetAllPaymentTermsQuery();


    const [createConsignment, { isLoading, isSuccess, error: createError }] =
        useCreateConsignmentMutation();

    const [getProductDetail, { data: productData, isLoading: productLoading }] =
        useLazyGetConsignmentContractProductDetailQuery();

    const productBarcodeRef = useRef(null);

    const { append, remove, update, replace } = useFieldArray({
        control,
        name: 'product_detail',
    });

    useErrorHandling(...castTypeArr<TFailResponse>([createError]));

    console.log(customerData, 'customerData');

    useEffect(() => {
        if (formValues?.customer_id && customerData?.data) {
            const customer = customerData?.data.find(
                (c: any) => c.customer_id == formValues?.customer_id
            );

            if (
                customer?.business_units &&
                customer.business_units.length > 0
            ) {
                setBusinessUnits(customer?.business_units || []);
            }
        }
    }, [formValues?.customer_id, customerData]);

    useEffect(() => {
        setValue('product_detail' , [])
        if (
            productData &&
            productData?.data.consignment_contract_details.length > 0
        ) {
            const productDetail =
                productData?.data?.consignment_contract_details.map(
                    (product: any) => ({
                        product_id: product.product_id,
                        unit_id: product.unit_id,
                        sales_price: product.secondary_sales_price,
                        qty: 0,
                        total_amount: 0 * product.secondary_sales_price,
                        product_code: product.product_code,
                        product_name: product.product_name,
                        unit_name: product.unit_name,
                        amount: 0 * product.secondary_sales_price,
                        discount: 0,
                        discount_amount: 0,
                        discount_type: 'AMT',
                        tax: product.secondary_sales_tax,
                        tax_amount: 0,
                        tax_type: product.secondary_sales_tax_type,
                        warehouse_name : product.warehouse_name,
                        contract_qty : product.consignment_contract_qty,
                        warehouse_id : product.warehouse_id
                    })
                );
            //@ts-ignore
            replace(productDetail);
            dispatch(replaceProductDetail(productDetail));
        }else {

        }
    }, [productData]);

    useEffect(() => {
        console.log('cakk', formValues.consignment_contract_id)
        if (customerData && customerData.data) {
            // Filter and map to get consignment_contract_id for the matching customer_id
            let [customer_id] = customerData.data
                .filter(
                    (data: any) => data.consignment_contract_id === formValues.consignment_contract_id
                )
                .map((data: any) => data.customer_id);
            console.log('customer Id', customer_id)
            if (formValues.consignment_contract_id && customer_id) {
                setValue('consignment_contract_id', formValues.consignment_contract_id);
                setValue('customer_id', customer_id)
                getProductDetail({
                    consignment_contract_id: formValues.consignment_contract_id,
                });
            }
        } else {
            console.log('customerData or customerData.data is undefined');
        }
    }, [formValues.consignment_contract_id]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const { product_detail, ...theRest } = value;

            const targetIndex = Number(name?.split('.')?.[1]);
            const targetProductDetail: any = product_detail![targetIndex!];

            if (targetProductDetail && product_detail) {
                if (targetProductDetail) {
                    let qty = targetProductDetail.qty

                    if (qty > targetProductDetail.contract_qty) {
                        qty = targetProductDetail.contract_qty;
                    }
                    let tax = isNaN(targetProductDetail.tax)
                        ? 0
                        : Number(targetProductDetail.tax);
                    let discount = isNaN(targetProductDetail.discount)
                        ? 0
                        : Number(targetProductDetail.discount);
                    const updatedProductAmount =
                        targetProductDetail.sales_price *
                        qty;

                    const updatedProductDiscount =
                        targetProductDetail.discount_type === '%'
                            ? updatedProductAmount *
                              (discount / 100)
                            : discount;

                    const updatedProductTax =
                        targetProductDetail.tax_type === '%'
                            ? updatedProductAmount *
                              (tax / 100)
                            : tax;

                    const updatedTotalAmount =
                        updatedProductAmount +
                        updatedProductTax -
                        updatedProductDiscount;

                    update(targetIndex, {
                        ...targetProductDetail,
                        discount_amount: truncateDecimal(updatedProductDiscount),
                        tax_amount: truncateDecimal(updatedProductTax),
                        // amount
                        amount: truncateDecimal(updatedProductAmount),
                        // total amount
                        total_amount: truncateDecimal(updatedTotalAmount),
                    });
                    // TO DO: check whether this is actaully necessary
                    updateProductDetail({
                        index: targetIndex,
                        product_detail: {
                            ...targetProductDetail,
                            discount_amount: truncateDecimal(updatedProductDiscount),
                            tax_amount: truncateDecimal(updatedProductTax),
                            amount: truncateDecimal(updatedProductAmount),
                            total_amount: truncateDecimal(updatedTotalAmount),
                        },
                    });
                }
            }

            // Calculate sub_total
            const subTotal =
                product_detail?.reduce((acc, cur) => {
                    if (cur) {
                        return acc + (cur.total_amount || 0);
                    }
                    return acc;
                }, 0) || 0;

            // Calculate discount_amount based on sub_total
            const discountAmount =
                theRest.discount_type === '%'
                    ? subTotal * (theRest.discount! / 100)
                    : theRest.discount || 0;

            // Calculate discount_amount based on sub_total
            const taxAmount =
                theRest.tax_type === '%'
                    ? subTotal * (theRest.tax! / 100)
                    : theRest.tax || 0;

            const otherCharges = theRest.other_charges || 0;

            const grandTotalAmount =  truncateDecimal(subTotal + otherCharges + taxAmount - discountAmount)

            //@ts-ignore
            dispatch(
                addFormValues({
                    ...theRest,
                    // @ts-ignore
                    product_detail: product_detail?.map((pd) => ({ ...pd })),
                    sub_total: subTotal,
                    discount_amount: truncateDecimal(discountAmount),
                    discount: theRest.discount || 0,
                    tax_amount: truncateDecimal(taxAmount),
                    tax: theRest.tax || 0,
                    other_charges: otherCharges,
                    grand_total_amount:
                    grandTotalAmount
                    //@ts-ignore
                    // sales_date: new Date(theRest.sales_date).toISOString(),
                })
            );
        });

        return () => {
            subscription.unsubscribe();
            dispatch(resetFormValues());
        };
    }, [watch, dispatch]);

    const handleRemove = (index: number) => {
        remove(index);
        dispatch(removeProductDetail(index));
    };
    const onSubmit: SubmitHandler<FormFields> = async (data) => {
        try {
            console.log(data);

            // async stuff here
            // console.log(data, 'data');
            await asyncDispatcher(data);
        } catch (error: any) {
            setError('root', {
                message: error.message,
            });
        }
    };

    console.log('formValues?.product_detail');
    console.log(formValues?.product_detail);
    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                const { product_detail, discount, tax, ...rest } = formValues;

                const formattedProductDetails = product_detail?.map(
                    (pd: any) => ({
                        product_id: pd.product_id,
                        unit_id: pd.unit_id,
                        // unit_name: pd.unit_name,
                        sales_price: pd.sales_price,
                        qty: pd.qty,
                        amount: pd.amount,
                        discount_type: pd.discount_type,
                        discount_amount: pd.discount_amount,
                        tax_type: pd.tax_type,
                        tax_amount: pd.tax_amount,
                        total_amount: pd.total_amount,
                        warehouse_id : pd.warehouse_id,
                        consignment_contract_qty : pd.contract_qty,
                        discount : pd.discount,
                        tax : pd.tax
                    })
                );

                console.log("Form", {
                        ...rest,
                        tax: formValues.tax,
                        discount: formValues.discount,
                        products: formattedProductDetails,
                    })

                const res = await createConsignment({
                    tax: formValues.tax,
                    discount: formValues.discount,
                    ...rest,
                    sales_date : formatDBDate(rest?.sales_date),
                    products: formattedProductDetails,
                });
                console.log("Result",res)
                const successData = 'data' in res ? res.data : null;
                const { success, message } = successData as TSuccessResponse;
                if (success || isSuccess) {
                    await Toast.fire({
                        title: message,
                        icon: 'success',
                    });
                    navigate(CONSIGNMENT.BASE_PATH, {
                        state: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                console.log('Error', error);
            }
        },
        [createConsignment, isSuccess, Toast, navigate, formValues]
    );

    console.log("formValues?.product_detail", formValues?.product_detail)

    return (
        <PageWrapper>
            {(isLoading || productLoading) && <LoadingSpinner />}
            <BackButton />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
                    Add New Consignment
                </h3>
                <div className="grid grid-cols-6 gap-x-4 gap-y-8">
                    <CustomDateField
                        errors={errors}
                        name="sales_date"
                        label="Sale Date"
                        register={register}
                        key="sales_date"
                        //@ts-ignore
                        control={control}
                        required
                    />
                    <CustomSearchModalChooseField
                        key={'consignment_contract_id'}
                        errors={errors}
                        colSpan={2}
                        name={'consignment_contract_id'}
                        label="Customer Name"
                        title={'Customer List'}
                        columns={[
                            {
                                name: 'customer_name',
                                columnName: 'Customer Name',
                            },
                            {
                                name: 'phone_number',
                                columnName: 'Phone Number',
                            },
                        ]}
                        // @ts-ignore
                        control={control}
                        register={register}
                        // borderColor={field.borderColor}
                        placeHolder="Choose customer name"
                        setValue={setValue}
                        // @ts-ignore
                        data={customerData?.data.map((customer) => ({
                            customer_id: customer.customer_id,
                            consignment_contract_id : customer.consignment_contract_id,
                            customer_name:
                                customer.customer_first_name +
                                ' ' +
                                (customer.customer_last_name || ''),
                            phone_number: customer.customer_phone1,
                        }))}
                        columnName={'Customer Name'}
                        idName={'consignment_contract_id'}
                        // value={field.value}
                        nameName={'customer_name'}
                        required

                    />
                    <CustomSelectField
                        disabled={getValues('customer_id') === undefined}
                        errors={errors}
                        name="business_unit_id"
                        label="Business Unit"
                        placeHolder="Select business unit"
                        //@ts-ignore
                        control={control}
                        options={businessUnits?.map((bu: any) => ({
                            label: bu.business_unit_name,
                            value: bu.business_unit_id,
                        }))}
                        required

                    />
                    <CustomSelectField
                        errors={errors}
                        name="payment_type_id"
                        label="Payment Type"
                        placeHolder="Select payment type"
                        //@ts-ignore
                        control={control}
                        options={[
                            // {
                            //     label: 'Credit',
                            //     value: 1,
                            // },
                            { label: 'Consignment', value: 2 },
                            // { label: 'Cashdown', value: 3 },
                        ]}
                        value={getValues('payment_type_id') || 2}
                        defaultValue={2}
                        required

                    />
                    <CustomSelectField
                        errors={errors}
                        name="payment_term_id"
                        label="Payment Term"
                        placeHolder="Select payment term"
                        //@ts-ignore
                        control={control}
                        options={paymentTermData?.map((item: any) => ({
                            label: item.payment_terms,
                            value: item.payment_terms_id,
                        }))}
                        required

                    />
                    {/* <CustomSearchModalChooseField
                        key={'warehouse_id'}
                        errors={errors}
                        colSpan={2}
                        // disabled
                        name={'warehouse_id'}
                        label="Allocation Account Name"
                        title={'Allocation Account List'}
                        columns={[
                            {
                                name: 'warehouse_name',
                                columnName: 'Warehouse Name',
                            },
                            {
                                name: 'warehouse_address',
                                columnName: 'Address',
                            },
                        ]}
                        register={register}
                        placeHolder="Choose Allocation Account name"
                        setValue={setValue}
                        data={warehouseData?.map((warehouse) => ({
                            warehouse_id: warehouse.warehouse_id,
                            warehouse_name: warehouse.warehouse_name,
                            warehouse_address: warehouse.warehouse_address,
                        }))}
                        columnName={'Allocation Account Name'}
                        idName={'warehouse_id'}
                        nameName={'warehouse_name'}
                        value={formValues?.warehouse_name}
                    /> */}
                    {/* <CustomTextAreaField
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    /> */}
                    <CustomTextAreaField
                        key={'description'}
                        errors={errors}
                        colSpan={6}
                        name="description"
                        register={register}
                    />
                    <div className="flex justify-between col-span-6">
                        <h3 className="ml-2 font-[600] text-primary-dark">
                            <span className=" text-[1.1rem]">
                                Add Consignment Detail
                            </span>
                        </h3>
                    </div>
                    <div className="flex flex-col justify-between col-span-6">
                        <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                            <span className=" text-[1.3rem]">
                                Product Detail
                            </span>
                        </h3>
                    </div>
                    <div className="-mt-4 col-span-full">
                        <DataTable
                            className="pb-4"
                            responsive
                            striped
                            //@ts-ignore
                            columns={consignmentproductDetailColumns(
                                errors,
                                register,
                                setValue,
                                handleRemove,
                                control
                            )}
                            //@ts-ignore
                            data={formValues?.product_detail?.map((f, i) => ({
                                ...f,
                                index: i,
                            }))}
                            noDataComponent={
                                <TableEmpty
                                    columnNames={
                                        consignmentproductDetailColumnNames
                                    }
                                />
                            }
                        />

                        {formValues?.product_detail?.length > 0 && (
                            <div className="bg-zinc-100 rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                                <div className="flex items-center mb-4">
                                    <h5 className="text-[1.05rem] basis-3/12">
                                        Sub Total
                                    </h5>
                                    <span className="s basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.sub_total || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Discount
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            defaultValue={0}
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'discount'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                        <select
                                            {...register('discount_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option selected value="AMT">
                                                AMT
                                            </option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        -
                                        {thousandSeperator(
                                            formValues.discount_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Tax
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            defaultValue={0}
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'tax'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                        <select
                                            {...register('tax_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option selected value="AMT">
                                                AMT
                                            </option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.tax_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-4">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Other Charges
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            defaultValue={0}
                                            classNames="-mr-3 w-[200px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'other_charges'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.other_charges || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className="text-xl font-semibold basis-3/12">
                                        Grand Total
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                                        {thousandSeperator(
                                            formValues.grand_total_amount || 0
                                        )}
                                    </h5>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ml-2 mt-14">
                    <CustomButton
                        isLarge
                        title="Cancel"
                        color="white"
                        textColor="gray.800"
                        className="mr-10"
                        handleClick={() => navigate(-1)}
                    />
                    <CustomButton
                        handleClick={handleSubmit(onSubmit)}
                        isLarge
                        title={isSubmitting ? 'Loading...' : 'Submit'}
                        disabled={isSubmitting}
                        type="submit"
                    />
                </div>
                {errors.root && (
                    <div className="mt-6 text-red-500">
                        {errors.root.message}
                    </div>
                )}
            </form>
        </PageWrapper>
    );
};

export default ConsignmentAddPage;
