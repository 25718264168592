
import { TWarehouse } from '@helpers/validation-schemas/warehouse-and-inventory/warehouse.schema';
import { TRegion } from '@type-defs/distribution-region/TRegion';
import { TWay } from '@type-defs/distribution-region/TWay';
// {
//     data: {
//         warehouses: TWarehouse[],states:any[], cities:any[],townships:any[]
//     };
//     // loadings: {
//     //     regionLoading: boolean;
//     // };
//     selectActions: {
//         warehouseSelectionAction : any;  
//         state: any,city: any,township: any
//     };
//     disabled: {
//         cityDisabled: boolean;
//         townshipDisabled: boolean;
//     };
//     selectedIds: {
//         warehouseSelectedIds : any;
//         division: string[] | number[];
//         city: string[] | number[];
//         township: string[] | number[];
//     };
   
//     // values?: {
//     //     country :any
//     // };
// }

export const tripFields = ({
    data: { 
        warehouses,states , cities,townships
    },
    selectActions: { 
        warehouseSelectionAction,
        stateSelectionAction,citySelectionAction,townshipSelectionAction
    },
    disabled:{
        cityDisabled,townshipDisabled
    },
    selectedIds: {
        warehouseSelectedIds,
        divisionSelectedIds,
        citySelectedIds,
        townshipSelectedIds
     },
    values:{
        warehousesValue
    },
    // selectMultipleIds:{
    //     warehouseSelectMutiIds
    // }
    
}: any) => {
   
    return [
        {
            name: 'trip_name',
            type: 'text',
            label: 'Trip Name',
            required: true,
            // removeSpaces : true
        },
        {
            name: 'warehouse_id',
            title: 'Allocation Account List',
            columnName: 'Allocation Account Name',
            idName: 'warehouse_id',
            nameName: 'warehouse_name',
            type: 'search-modal',
            label: 'Allocation Account Name',
            single: false,
            // disabled: regionLoading,
            placeHolder: 'Choose allocation account name',
            data: warehouses?.map((w:any) => ({
                warehouse_id: w.warehouse_id,
                warehouse_name: w.warehouse_name,
                warehouse_address: w.warehouse_address || '-',
            })),
            action: warehouseSelectionAction,
            selectIds: warehouseSelectedIds,
            required: true,
            columns: [
                {
                    name: 'warehouse_name',
                    columnName: 'Allocation Account Name',
                },
                {
                    name: 'warehouse_address',
                    columnName: 'Address',
                },
            ],
            // value : warehousesValue
            // selectIds: warehouseSelectMutiIds,

        },
        {
            name: 'Empty Column 1',
            type: 'empty-column',
        },
        {
            name: 'state_id',
            title: 'Division/State List',
            columnName: 'Division/State Name',
            idName: 'state_id',
            nameName: 'name',
            type: 'search-modal',
            label: 'State/Division Name',
            placeHolder: 'Select State/Division',
            data: states.map((s:any) => ({
                state_id: s.stateid,
                name: s.name,
            })),
            action: stateSelectionAction,
            selectIds: divisionSelectedIds,
            required: true,
            columns: [
                {
                    name: 'name',
                    columnName: 'State/Division Name',
                },
            ],
        },
        {
            name: 'city_id',
            title: 'City List',
            columnName: 'City Name',
            idName: 'city_id',
            nameName: 'cityname',
            type: 'search-modal',
            label: 'City Name',
            placeHolder: 'Select City Name',
            data: cities.map((c:any) => ({
                city_id: c.cityid,
                cityname: c.cityname,
                state_id: c.state_id,
                state_name: c.state_name,
            })),
            action: citySelectionAction,
            selectIds: citySelectedIds,
            required: true,
            columns: [
                {
                    name: 'cityname',
                    columnName: 'City Name',
                },
                {
                    name: 'state_name',
                    columnName: 'State/Division Name',
                },
            ],
        },
        {
            name: 'township_id',
            title: 'Township List',
            columnName: 'Township Name',
            idName: 'township_id',
            nameName: 'townshipname',
            type: 'search-modal',
            label: 'Township Name',
            placeHolder: 'Select Township Name',
            data: townships?.map((t:any) => ({
                township_id: t.townshipid,
                townshipname: t.townshipname,
                city_id: t.city_id,
                city_name: t.city_name,
                state_id: t.state_id,
                state_name: t.state_name,
            })),
            action: townshipSelectionAction,
            selectIds: townshipSelectedIds,
            required: true,
            columns: [
                {
                    name: 'townshipname',
                    columnName: 'Township Name',
                },
                {
                    name: 'city_name',
                    columnName: 'City Name',
                },
                {
                    name: 'state_name',
                    columnName: 'State/Division Name',
                },
            ],
        },
        
        {
            name: 'Empty Column 2',
            type: 'empty-column',
        },
        {
            name: 'Empty Column 3',
            type: 'empty-column',
        },
        {
            name: 'Trip Detail List',
            type: 'custom-component',
        },
        // {
        //     name: 'remark',
        //     type: 'textarea',
        //     colSpan: 3,
        // },
        {
            name: 'description',
            type: 'textarea',
            colSpan: 6,
        },
    ];
};




















/// OLd




// import { TWarehouse } from '@helpers/validation-schemas/warehouse-and-inventory/warehouse.schema';
// import { TRegion } from '@type-defs/distribution-region/TRegion';
// import { TWay } from '@type-defs/distribution-region/TWay';

// export const tripFields = ({
//     data: { regions, ways, warehouses ,states ,cities , townships,cityDisabled},
//     loadings: { regionLoading },
//     selectActions: { regionSelectAction, waySelectAction  , allocationSelectAction,
//         citySelectAction,townshipSelectAction,stateSelectAction
//     },
//     selectedIds: { regionSelectedIds, waySelectedIds ,
//         townshipSelectId,
//         allocationSelectedIds,divisionSelectId, citySelectId},
//     // values:{
//     //     // cityValue,townshipValue,divisionValue
//     // },
// }: {
//     data: {
//         regions: TRegion[];
//         ways: TWay[];
//         warehouses: TWarehouse[];
//         states : any[];
//         cities:any[];
//         townships:any[];
//         cityDisabled?:boolean
//     };
//     loadings: {
//         regionLoading: boolean;
//     };
//     selectActions: {
//         regionSelectAction: any;
//         waySelectAction: any;
//         allocationSelectAction:any;
//         citySelectAction:any;
//         townshipSelectAction:any;
//         stateSelectAction:any;
//     };
//     selectedIds: {
//         regionSelectedIds: number[];
//         waySelectedIds: number[];
//         allocationSelectedIds:number[];
//         divisionSelectId:number[];
//         citySelectId:number[];
//         townshipSelectId:number[];
//     };

//     // values?: {
//     //     divisionValue?: string;
//     //     cityValue?: string;
//     //     townshipValue?: string;
//     // };
// }) => {
   

//     return [
//         {
//             name: 'trip_name',
//             type: 'text',
//             label: 'Trip Name',
//             required: true,
//         },
//         //TODO: bind actual API data
//         // {
//         //     name: 'warehouse_id',
//         //     title: 'Allocation Account List',
//         //     columnName: 'Allocation Account Name',
//         //     columns: [
//         //         {
//         //             name: 'warehouse_name',
//         //             columnName: 'Allocation Account Name',
//         //         },
//         //         {
//         //             name: 'warehouse_address',
//         //             columnName: 'Address',
//         //         },
//         //     ],
//         //     idName: 'warehouse_id',
//         //     nameName: 'warehouse_name',
//         //     type: 'search-modal-choose',
//         //     single : false,
//         //     label: 'Allocation Account Name',
//         //     placeHolder: 'Choose allocation account name',
//         //     data: warehouses?.map((w) => ({
//         //         warehouse_id: w.warehouse_id,
//         //         warehouse_name: w.warehouse_name,
//         //         warehouse_address: w.warehouse_address || '-',
//         //     })),
//         //     value: values?.warehouse,
//         //     required: true,
//         // },
//         {
//             name: 'warehouse_id',
//             title: 'Allocation Account List',
//             columnName: 'Allocation Account Name',
//             idName: 'warehouse_id',
//             nameName: 'warehouse_name',
//             type: 'search-modal',
//             label: 'Allocation Account Name',
//             single: false,
//             disabled: regionLoading,
//             placeHolder: 'Choose allocation account name',
//             data: warehouses?.map((w) => ({
//                 warehouse_id: w.warehouse_id,
//                 warehouse_name: w.warehouse_name,
//                 warehouse_address: w.warehouse_address || '-',
//             })),
//             action: allocationSelectAction,
//             selectIds: allocationSelectedIds,
//             required: true,
//             columns: [
//                 {
//                     name: 'warehouse_name',
//                     columnName: 'Allocation Account Name',
//                 },
//                 {
//                     name: 'warehouse_address',
//                     columnName: 'Address',
//                 },
//             ],
//         },
//         {
//             name: 'Empty Column 1',
//             type: 'empty-column',
//         },
//         // {
//         //     name: 'region_id',
//         //     title: 'Region List',
//         //     columnName: 'Region Name',
//         //     idName: 'region_id',
//         //     nameName: 'region_name',
//         //     type: 'search-modal',
//         //     label: 'Region Name',
//         //     single: false,
//         //     disabled: regionLoading,
//         //     placeHolder: 'Select region name',
//         //     data: regions?.map((r) => ({
//         //         //@ts-ignore
//         //         region_id: r.region_id,
//         //         region_name: r.region_name,
//         //     })),
//         //     action: regionSelectAction,
//         //     selectIds: regionSelectedIds,
//         //     required: true,
//         //     columns: [
//         //         {
//         //             name: 'region_name',
//         //             columnName: 'Region Name',
//         //         },
//         //     ],
//         // },
//         // {
//         //     name: 'way_id',
//         //     title: 'Way List',
//         //     columnName: 'Way Name',
//         //     idName: 'way_id',
//         //     nameName: 'way_name',
//         //     type: 'search-modal',
//         //     label: 'Way Name',
//         //     single: false,
//         //     disabled: regionLoading,
//         //     placeHolder: 'Select way name',
//         //     data: ways?.map((w) => ({
//         //         //@ts-ignore
//         //         way_id: w.way_id,
//         //         way_name: w.way_name,
//         //     })),
//         //     action: waySelectAction,
//         //     selectIds: waySelectedIds,
//         //     required: true,
//         //     columns: [
//         //         {
//         //             name: 'way_name',
//         //             columnName: 'Way Name',
//         //         },
//         //     ],
//         // },
//         {
//             name: 'state_name',
//             title: 'Division/State List',
//             columnName: 'Division/State Name',
//             idName: 'state_id',
//             nameName: 'name',
//             type: 'search-modal',
//             label: 'State/Division Name',
//             placeHolder: 'Select State/Division',
//             data: states?.map((s) => ({
//                 state_id: s.stateid,
//                 name: s.name,
//             })),
//             single: true,
//             action: stateSelectAction,
//             selectId: divisionSelectId,
//             columns: [
//                 {
//                     name: 'name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: divisionValue,
//         },
//         {
//             name: 'city_name',
//             title: 'City List',
//             columnName: 'City Name',
//             idName: 'city_id',
//             nameName: 'cityname',
//             type: 'search-modal',
//             label: 'City Name',
//             placeHolder: 'Select City Name',
//             data: cities?.map((c) => ({
//                 city_id: c.cityid,
//                 cityname: c.cityname,
//                 state_id: c.state_id,
//                 state_name: c.state_name,
//             })),
//             action: citySelectAction,
//             selectId: citySelectId,
//             single: true,
//             disabled: cityDisabled,
//             columns: [
//                 {
//                     name: 'cityname',
//                     columnName: 'City Name',
//                 },
//                 {
//                     name: 'state_name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: cityValue,
//         },
//         {
//             name: 'townshipname',
//             title: 'Township List',
//             columnName: 'Township Name',
//             idName: 'township_id',
//             nameName: 'townshipname',
//             type: 'search-modal',
//             label: 'Township Name',
//             placeHolder: 'Select Township Name',
//             data: townships?.map((t) => ({
//                 township_id: t.townshipid,
//                 townshipname: t.townshipname,
//                 city_id: t.city_id,
//                 city_name: t.city_name,
//                 state_id: t.state_id,
//                 state_name: t.state_name,
//             })),
//             single: true,
//             disabled: townshipDisabled,
//             action: townshipSelectAction,
//             selectId: townshipSelectId,
//             columns: [
//                 {
//                     name: 'townshipname',
//                     columnName: 'Township Name',
//                 },
//                 {
//                     name: 'city_name',
//                     columnName: 'City Name',
//                 },
//                 {
//                     name: 'state_name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: townshipValue,
//         },
//         {
//             name: 'Empty Column 2',
//             type: 'empty-column',
//         },
//         {
//             name: 'Empty Column 3',
//             type: 'empty-column',
//         },
//         {
//             name: 'Trip Detail List',
//             type: 'custom-component',
//         },
//         {
//             name: 'remark',
//             type: 'textarea',
//             colSpan: 3,
//         },
//         {
//             name: 'description',
//             type: 'textarea',
//             colSpan: 3,
//         },
//     ];
// };
