// export function formatDBDate(date: Date) {
//     // Get the year, month, and day
//         let year = date.getFullYear();
//         let month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
//         let day = ('0' + date.getDate()).slice(-2);
    
//         // Concatenate them in the desired format
//         let formattedDate = `${year}-${month}-${day}`;
    
//         return formattedDate;
// }   

export function formatDBDate(date: Date | string) {
    // Check if the input is an empty string, return an empty string
    if (date === '') return '';
    
    // Parse the date if it's a Date object
    const parsedDate = typeof date === 'string' ? new Date(date) : date;

    // Get the year, month, and day
    let year = parsedDate.getFullYear();
    let month = ('0' + (parsedDate.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
    let day = ('0' + parsedDate.getDate()).slice(-2);

    // Concatenate them in the desired format
    let formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}