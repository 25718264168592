import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_MANAGEMENT } from '@utils/constants/trip/trip.constants';

export const tripApi = createApi({
    reducerPath: 'tripApi',
    tagTypes: ['Trip'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTrips: builder.query<
            [],
            { page?: number; limit?: number; product_tag_name?: string , trip_name?:string , trip_code?:string }
        >({
            query: ({ page = 1, limit = 10, product_tag_name ,trip_name,trip_code}) => {
                const queryParams = {
                    url: TRIP_MANAGEMENT.GET_ALL,
                    params: { page, limit },
                };

                if (product_tag_name) {
                    //@ts-ignore
                    queryParams.params.product_tag_name = product_tag_name;
                }
                if (trip_name) {
                    //@ts-ignore
                    queryParams.params.trip_name = trip_name;
                }
                if (trip_code) {
                    //@ts-ignore
                    queryParams.params.trip_code = trip_code;
                }


                return queryParams;
            },
        }),
        getTrip: builder.query<any, { id: string | number }>({
            query: ({ id }) => TRIP_MANAGEMENT.GET_ONE + id,
        }),
        createTrip: builder.mutation({
            query: (body) => ({
                url: TRIP_MANAGEMENT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateTrip: builder.mutation({
            query: ({ id, body }) => ({
                url: `${TRIP_MANAGEMENT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteTrip: builder.mutation({
            query: (id) => ({
                url: `${TRIP_MANAGEMENT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllTripsQuery,
    useGetTripQuery,
    useCreateTripMutation,
    useUpdateTripMutation,
    useDeleteTripMutation,
} = tripApi;
