export const STOCK_ADJUSTMENT = {
    BASE_PATH: '/stock-and-inventory/stock-adjustment/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Stock Adjustment',
    LIST_PAGE_TITLE: 'Stock Adjustment List',
    EDIT_PAGE_TITLE: 'Edit Stock Adjustment',
    GET_ALL: 'inventory-adjustment',
    GET_ONE: 'inventory-adjustment/edit/',
    CREATE: 'inventory-adjustment/create',
    UPDATE: 'inventory-adjustment/update/',
    DELETE: 'inventory-adjustment/delete/',
    ADD_NEW_TITLE: 'Add New Stock Adjustment',
};
