import DeleteButton from '@components/buttons/DeleteButton';
import EditButton from '@components/buttons/EditButton';
import OutlinedStatus from '@components/status/OutlinedStatus';
import { thousandSeperator } from '@utils/general/digit-separators';
import { formatDate } from '@utils/general/format-date';

export const invoiceColumns = (
    editHandler: (invoice: any) => void,
    deleteHandler: (id: string | number) => void
) => [
    {
        name: 'Invoice ID',
        selector: (row: any) => (
            <div className="font-semibold text-primary-dark">
                {row.consignment_invoice_code}
            </div>
        ),
        sort: true,
        grow: 1.2,
    },
    {
        name: 'Consignment ID',
        selector: (row: any) => (
            <div className="font-semibold text-primary-dark">
                {row.consignment_code}
            </div>
        ),
        sort: true,
        grow: 1.2,
    },
    {
        name: 'Invoice Date',
        selector: (row: any) => formatDate(new Date(row.invoice_date)),
        sort: true,
    },
    {
        name: 'Sales Date',
        selector: (row: any) => formatDate(new Date(row.sales_date)),
        sort: true,
    },
    {
        name: 'Grand Total Amount',
        selector: (row: any) => thousandSeperator(row.grand_total_amount),
        sort: true,
    },
    {
        name: 'Paid Amount',
        selector: (row: any) =>
            thousandSeperator(row.total_payment_receive_amount || 0),
        sort: true,
    },
    {
        name: 'Balance',
        selector: (row: any) => thousandSeperator(row.balance || 0),
        sort: true,
    },
    {
        name: 'Status',
        selector: (row: any) => (
            <OutlinedStatus
                width="w-[13ch]"
                title={
                    row.status == 1
                        ? 'Open'
                        : row.status == 2
                        ? 'Partial Paid'
                        : 'Paid'

                }
                color={
                    row.status == 1
                        ? 'blue'
                        : row.status == 2
                        ? 'purple'
                        : 'green'
                }
            />
        ),
        sort: true,
        grow: 1.1,
    },
    {
        name: 'Action',
        right: 'true',
        selector: (row: any) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <EditButton
                    disabled={row.status != 1 && row.status != 2}
                    id={row.consignment_invoice_id}
                    actionHandler={() => editHandler(row)}
                />
                <DeleteButton
                    id={row.consignment_invoice_id}
                    actionHandler={() =>
                        deleteHandler(row.consignment_invoice_id)
                    }
                />
            </div>
        ),
    },
];
