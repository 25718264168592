import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { WAREHOUSE } from '@utils/constants/warehouse.constants';
import { WAREHOUSE_USER_ASSIGN } from '@utils/constants/warehouse-user-assign.constants';

export const warehouseApi = createApi({
    reducerPath: 'warehouseApi',
    tagTypes: ['Warehouse'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllWarehouses: builder.query<
            [],
            { page?: number; limit?: number; warehouse_name?: string }
        >({
            query: ({ page = 1, limit = 10, warehouse_name }) => {
                const queryParams = {
                    url: WAREHOUSE.GET_ALL,
                    params: { page, limit },
                };

                if (warehouse_name) {
                    //@ts-ignore
                    queryParams.params.warehouse_name = warehouse_name;
                }

                return queryParams;
            },
        }),
        createWarehouse: builder.mutation({
            query: (body) => ({
                url: WAREHOUSE.CREATE,
                method: 'POST',
                body,
            }),
        }),

        getWarehouse: builder.query<any, { id: string | number }>({
            query: ({ id }) => WAREHOUSE.GET_ONE + id,
        }),

        getProductCategoryByWarehouse: builder.query<any, { id: string | number }>({
            query: ({ id }) => `/consignment/contract/product/category/${id}`
        }),

        


        updateWarehouse: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WAREHOUSE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteWarehouse: builder.mutation({
            query: (id) => ({
                url: `${WAREHOUSE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0

});

export const {
    useGetAllWarehousesQuery,
    useCreateWarehouseMutation,
    useUpdateWarehouseMutation,
    useDeleteWarehouseMutation,
    useLazyGetWarehouseQuery,
    useLazyGetProductCategoryByWarehouseQuery
} = warehouseApi;
