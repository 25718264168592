import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    tagTypes: ['UserRole', 'CustomerHistory'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllUserRoles: builder.query<[], void>({
            query: () => 'user-roles',
        }),
        createUserRole: builder.mutation({
            query: (body) => ({
                url: 'user-roles/create',
                method: 'POST',
                body,
            }),
        }),
        getAllModules: builder.query<
            [],
            {
                channel_id?: number;
            }
        >({
            query: ({ channel_id }) => {
                const queryParams = {
                    url: 'module',
                    params: { channel_id: 1 },
                };

                if (channel_id) {
                    queryParams.params.channel_id = channel_id;
                }

                return queryParams;
            },
        }),
        getWarehousesByUser: builder.query<
            [],
            {
                user_id?: number;
            }
        >({
            query: ({ user_id }) => {
                const queryParams = {
                    url: 'common/warehouse-list',
                    params: { user_id },
                };

                return queryParams;
            },
        }),
        getCustomerByUser: builder.query<
            [],
            {
                user_id?: number;
            }
        >({
            query: ({ user_id }) => {
                const queryParams = {
                    url: 'common/customer-list',
                    params: { user_id },
                };

                return queryParams;
            },
        }),
        getProductDetailByWarehouse: builder.query<
            [],
            {
                product_id?: number;
                warehouse_id?: number;
            }
        >({
            query: ({ product_id, warehouse_id }) => {
                const queryParams = {
                    url: 'common/warehouse-list/product',
                    params: { product_id, warehouse_id },
                };

                return queryParams;
            },
        }),
        getProductListByWarehouse: builder.query<
            [],
            {
                warehouse_id?: number;
            }
        >({
            query: ({ warehouse_id }) => {
                const queryParams = {
                    url: 'common/warehouse-product',
                    params: {  warehouse_id },
                };

                return queryParams;
            },
        }),
        getAllCustomerTypes: builder.query<[], void>({
            query: () => 'common/customer-type',
        }),
        getAllCreditTypes: builder.query<[], void>({
            query: () => 'common/credit-type',
        }),
        getAllSaleType: builder.query<[], void>({
            query: () => 'common/sale-type',
        }),
        getAllPaymentTypes: builder.query<[], void>({
            query: () => 'common/payment-type',
        }),
        getAllProductTypes: builder.query<[], void>({
            query: () => 'common/product-type',
        }),
        getAllSKU: builder.query<[], void>({
            query: () => 'common/sku',
        }),
        getAllPaymentTerms: builder.query<[], void>({
            query: () => 'common/payment-terms',
        }),
        getAllStates: builder.query<[], void>({
            query: () => 'common/location',
        }),
        getAllSubModulesByModuleId: builder.mutation({
            query: (body) => ({
                url: 'sub-module?limit=1000',
                method: 'POST',
                body,
            }),
        }),
        getCustomerAllCreditLimits: builder.query<
            [],
            {
                customerId: string | number;
            }
        >({
            query: ({ customerId }) =>
                `common/customer-credit-limit/${customerId}`,
            providesTags: ['CustomerHistory'],
        }),
    }),
    keepUnusedDataFor:0,
});

export const {
    useGetAllUserRolesQuery,
    useGetAllModulesQuery,
    useGetWarehousesByUserQuery,
    useGetCustomerByUserQuery,
    useLazyGetAllModulesQuery,
    useLazyGetProductDetailByWarehouseQuery,
    useLazyGetProductListByWarehouseQuery,
    useGetAllStatesQuery,
    useCreateUserRoleMutation,
    useGetAllPaymentTermsQuery,
    useGetAllPaymentTypesQuery,
    useGetAllProductTypesQuery,
    useGetAllSKUQuery,
    useGetAllCreditTypesQuery,
    useGetAllSubModulesByModuleIdMutation,
    useGetAllCustomerTypesQuery,
    useGetCustomerAllCreditLimitsQuery,
    useGetAllSaleTypeQuery
} = commonApi;
