import React from 'react';

interface CheckboxOption {
    value: string | number;
    label: string;
}

interface CustomCheckboxGroupProps {
    options: CheckboxOption[];
    name: string;
    selectedValues: Array<string | number>;
    onChange: (value: Array<string | number>) => void;
    label?: string;
    colSpan?: number;
}

const CustomCheckboxGroup: React.FC<CustomCheckboxGroupProps> = ({
    options,
    name,
    selectedValues = [],
    onChange,
    label,
    colSpan,
}) => {
    // Helper function to handle checkbox changes
    const handleCheckboxChange = (value: string | number) => {
        //@ts-ignore
        if (selectedValues?.includes(value)) {
            // Remove the value if it's already selected
            onChange(selectedValues.filter((v) => v !== value));
        } else {
            // Add the value if it's not selected
            onChange([...selectedValues, value]);
        }
    };

    return (
        <div
            style={{
                gridColumn: colSpan ? `auto / span ${colSpan}` : 'auto / span 2',
            }}
            className="flex flex-col p-2"
        >
            {label && <h3 className="text-gray-700 mb-2">{label}</h3>}
            <div className="flex gap-5 mt-2">
                {options.map((option) => (
                    <div
                        key={option.value}
                        className="flex justify-start gap-2 items-center"
                    >
                        <input
                            type="checkbox"
                            name={name}
                            value={option.value}
                            id={`${name}-${option.value}`}
                            checked={selectedValues.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                            className="cursor-pointer"
                        />
                        <label
                            htmlFor={`${name}-${option.value}`}
                            className="cursor-pointer text-gray-700"
                        >
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomCheckboxGroup;
