import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PRIMARY_SALE_ORDER } from '@utils/constants/primary-sale-order.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const primarySaleOrderApi = createApi({
    reducerPath: 'primarySaleOrderApi',
    tagTypes: ['PrimarySaleOrder'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllPrimarySaleOrders: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_name?: string;
                primary_sales_order_code?: string;
                customer_id?: string | number;
                payment_type_id?: string | number;
                from_date?: Date | string;
                to_date?: Date | string;
                status?: string | number;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_name,
                primary_sales_order_code,
                customer_id,
                payment_type_id,
                from_date,
                to_date,
                status,
            }) => {
                const queryParams = {
                    url: PRIMARY_SALE_ORDER.GET_ALL,
                    params: { page, limit },
                };

                if (customer_name) {
                    //@ts-ignore
                    queryParams.params.customer_name = customer_name;
                }

                if (primary_sales_order_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_order_code =
                        primary_sales_order_code;
                }

                if (customer_id) {
                    //@ts-ignore
                    queryParams.params.customer_id = customer_id;
                }

                if (payment_type_id) {
                    //@ts-ignore
                    queryParams.params.payment_type_id = payment_type_id;
                }

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                if (status) {
                    //@ts-ignore
                    queryParams.params.status = status;
                }

                return queryParams;
            },
        }),
        getPrimarySaleOrder: builder.query<
            any,
            { primary_sales_order_id: string | number }
        >({
            query: ({ primary_sales_order_id }) =>
                `${PRIMARY_SALE_ORDER.GET_ONE}${primary_sales_order_id}`,
        }),
        createPrimarySaleOrder: builder.mutation({
            query: (body) => ({
                url: PRIMARY_SALE_ORDER.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePrimarySaleOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRIMARY_SALE_ORDER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        approvePrimaryOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRIMARY_SALE_ORDER.APPROVE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePrimarySaleOrder: builder.mutation({
            query: (id) => ({
                url: `${PRIMARY_SALE_ORDER.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllPrimarySaleOrdersQuery,
    useGetPrimarySaleOrderQuery,
    useApprovePrimaryOrderMutation,
    useCreatePrimarySaleOrderMutation,
    useUpdatePrimarySaleOrderMutation,
    useDeletePrimarySaleOrderMutation,
} = primarySaleOrderApi;
