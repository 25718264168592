export const TRIP_SALES_REQUEST = {
    BASE_PATH: '/trip/trip-sales-request/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Trip Sales Request',
    LIST_PAGE_TITLE: 'Trip Sales Request List',
    EDIT_PAGE_TITLE: 'Edit Trip Sales Request',
    GET_ALL: '/trip/sale-request',
    GET_ONE: '/trip/sale-request/edit/',
    DETAIL_ONE: '/trip/sale-request/detail/',
    CREATE: '/trip/sale-request/create',
    UPDATE: '/trip/sale-request/update/',
    DELETE: '/trip/sale-request/delete/',
    ADD_NEW_TITLE: 'Add New Trip Sales Request',
};
