import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { SECONDARY_PAYMENT_RECEIVE } from '@utils/constants/secondary-payment-receive.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const secondaryPaymentReceiveApi = createApi({
    reducerPath: 'secondaryPaymentReceiveApi',
    tagTypes: ['SecondaryPaymentReceive'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondaryPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: SECONDARY_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        getSecondaryPaymentReceivve: builder.query<
            any,
            { secondary_sales_payment_receive_id: string | number }
        >({
            query: ({ secondary_sales_payment_receive_id }) => {
                return `${SECONDARY_PAYMENT_RECEIVE.GET_ONE}${secondary_sales_payment_receive_id}`;
            },
        }),
        createSecondaryPaymentReceive: builder.mutation({
            query: (body) => ({
                url: SECONDARY_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteSecondaryPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${SECONDARY_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllSecondaryPaymentReceivesQuery,
    useLazyGetSecondaryPaymentReceivveQuery,
    useCreateSecondaryPaymentReceiveMutation,
    useDeleteSecondaryPaymentReceiveMutation,
} = secondaryPaymentReceiveApi;
