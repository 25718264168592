export const TRIP_USER_ASSIGN = {
    BASE_PATH: '/trip/trip-user-assign/',
    GET_ALL: '/trip/user-assign',
    GET_ONE: '/trip/user-assign/edit/',
    GET_DETAIL: 'trip-user-assign/detail/',
    CREATE: '/trip/user-assign/create',
    UPDATE: '/trip/user-assign/update/',
    DELETE: '/trip/user-assign/delete/',
    ADD_NEW_TITLE: 'Add New Trip User Assign',
};
