import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { STOCK_ADJUSTMENT } from '@utils/constants/warehouse-and-inventory/stock-adjustment.constants';

export const stockAdjustmentApi = createApi({
    reducerPath: 'stockAdjustmentApi',
    tagTypes: ['StockAdjustment'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllStockAdjustments: builder.query<
            [],
            { page?: number; limit?: number }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: STOCK_ADJUSTMENT.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        getStockAdjustment: builder.query<any, { id: string | number }>({
            query: ({ id }) => STOCK_ADJUSTMENT.GET_ONE + id,
        }),
        createStockAdjustment: builder.mutation({
            query: (body) => ({
                url: STOCK_ADJUSTMENT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateStockAdjustment: builder.mutation({
            query: ({ id, body }) => ({
                url: `${STOCK_ADJUSTMENT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteStockAdjustment: builder.mutation({
            query: (id) => ({
                url: `${STOCK_ADJUSTMENT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0

});

export const {
    useGetAllStockAdjustmentsQuery,
    useGetStockAdjustmentQuery,
    useCreateStockAdjustmentMutation,
    useUpdateStockAdjustmentMutation,
    useDeleteStockAdjustmentMutation,
} = stockAdjustmentApi;
