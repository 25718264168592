import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { DIRECT_SALES_ORDER } from '@utils/constants/direct-sales-order.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const directSaleOrderApi = createApi({
    reducerPath: 'directSaleOrderApi',
    tagTypes: ['DirectSaleOrder'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllDirectSaleOrders: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_name?: string;
                direct_sales_order_code?: string;
                customer_id?: string | number;
                payment_type_id?: string | number;
                from_date?: Date | string;
                to_date?: Date | string;
                status?: string | number;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_name,
                direct_sales_order_code,
                customer_id,
                payment_type_id,
                from_date,
                to_date,
                status,
            }) => {
                const queryParams = {
                    url: DIRECT_SALES_ORDER.GET_ALL,
                    params: { page, limit },
                };

                if (customer_name) {
                    //@ts-ignore
                    queryParams.params.customer_name = customer_name;
                }

                if (direct_sales_order_code) {
                    //@ts-ignore
                    queryParams.params.direct_sales_order_code =
                        direct_sales_order_code;
                }

                if (customer_id) {
                    //@ts-ignore
                    queryParams.params.customer_id = customer_id;
                }

                if (payment_type_id) {
                    //@ts-ignore
                    queryParams.params.payment_type_id = payment_type_id;
                }

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                if (status) {
                    //@ts-ignore
                    queryParams.params.status = status;
                }

                return queryParams;
            },
        }),
        getDirectSaleOrder: builder.query<
            any,
            { direct_sales_order_id: string | number }
        >({
            query: ({ direct_sales_order_id }) =>
                `${DIRECT_SALES_ORDER.GET_ONE}${direct_sales_order_id}`,
        }),
        createDirectSaleOrder: builder.mutation({
            query: (body) => ({
                url: DIRECT_SALES_ORDER.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateDirectSaleOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${DIRECT_SALES_ORDER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        approveDirectOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${DIRECT_SALES_ORDER.APPROVE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteDirectSaleOrder: builder.mutation({
            query: (id) => ({
                url: `${DIRECT_SALES_ORDER.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllDirectSaleOrdersQuery,
    useGetDirectSaleOrderQuery,
    useCreateDirectSaleOrderMutation,
    useApproveDirectOrderMutation,
    useUpdateDirectSaleOrderMutation,
    useDeleteDirectSaleOrderMutation,
} = directSaleOrderApi;
