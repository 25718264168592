import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { GOODS_DELIVERY_NOTE } from '@utils/constants/goods-request/delivery-note.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const deliveryNoteApi = createApi({
    reducerPath: 'deliveryNoteApi',
    tagTypes: ['DeliveryNote'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllDeliveryNotes: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                direct_sales_invoice_code?: string;
                direct_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                direct_sales_invoice_code,
                direct_sales_return_code,
            }) => {
                const queryParams = {
                    url: GOODS_DELIVERY_NOTE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        getGoodsDelivery: builder.query<
            any,
            { goods_delivery_id: string | number }
        >({
            query: ({ goods_delivery_id }) =>
                `${GOODS_DELIVERY_NOTE.GET_ONE}${goods_delivery_id}`,
        }),
        updateGoodsDelivery: builder.mutation({
            query: ({ id, body }) => ({
                url: `${GOODS_DELIVERY_NOTE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const { useGetAllDeliveryNotesQuery , useGetGoodsDeliveryQuery ,useUpdateGoodsDeliveryMutation } = deliveryNoteApi;
