import logoWithText from '@assets/images/Ye_Yint Logo.png';
import useGetAllOfficeUsers from '@hooks/user-management/useGetAllOfficeUsers';
import { selectCurrentUser } from '@states/auth/auth.slice';
import { LOCAL_STORAGE_KEYS } from '@utils/constants/local-storeage-keys.constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
    const user = useSelector(selectCurrentUser);
    const storedUser = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_KEY);
    const [userRoleName, setUserRoleName] = useState('');

    // const userData = user;
    const { officeUserData } = useGetAllOfficeUsers({ limit: 10000 });

    const userData = user || (storedUser ? JSON.parse(storedUser!) : null);
    useEffect(() => {
        if (userData && officeUserData) {
            const target = officeUserData.find(
                (ou) => ou.user_id == userData.user_id
            );

            if (target?.user_role_name) {
                setUserRoleName(target.user_role_name);
            }
        }
    }, [userData]);

    return (
        <div className="flex items-center justify-between h-24 bg-white px-9">
            <div className="flex items-center">
                <img width={210} alt="AMT" src={logoWithText} />
            </div>
            <div className="flex items-center">
                <div className="flex items-center justify-center w-12 h-12 rounded-full shadow-sm bg-primary">
                    <span className="text-xl font-bold text-white">A</span>
                </div>
                <div className="ml-2">
                    <div className="flex items-center">
                        <span className="mr-3">{userData?.username || ''}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 -960 960 960"
                            width="20px"
                            fill="#6b7280"
                        >
                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                        </svg>
                    </div>
                    <div className="text-xs text-gray-400">
                        {userRoleName ||
                            userData?.user_role_name ||
                            userData?.user_role_id}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
