export const reactSelectStyles = {
    control: (provided) => ({
        ...provided,
        marginTop: '0.5rem',
        borderColor: 'rgb(128,149,187)',
        borderWidth: '0.5px',
        height: 34,
        minHeight: 33,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '0.90rem',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.90rem',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => ({
        ...base,
        backgroundColor: isSelected
            ? 'rgb(45,120,219)'
            : isFocused
            ? '#e5e7eb'
            : '',
        color: isSelected ? '#ffffff' : '',
        fontSize: '0.90rem',
    }),
};
