import { TPrimaryInvoice } from '@helpers/validation-schemas/primary-sale-order/invoice.schema';
import { TPrimarySaleOrder } from '@helpers/validation-schemas/primary-sale-order/sale-order.schema';
import { useGetPrimaryInvoiceQuery } from '@states/primary-sale-order/primary-invoice.api';
import { TGetOneSuccessResponse } from '@type-defs/general/TGetOneSuccessResponse';
import {useGetConsignmentInvoiceQuery} from '@states/consignment/consignment-invoice.api'
const useGetOneConsignmentInvoice = (id: string | number) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch
    } = useGetConsignmentInvoiceQuery({
        consignment_invoice_id: id,
    });

    const consignmentInvoiceResponse =
        rawData as any 

    return {
        consignmentInvoiceData: consignmentInvoiceResponse?.data,
        consignmentInvoiceMessage: consignmentInvoiceResponse?.message,
        consignmentInvoiceSuccess: consignmentInvoiceResponse?.success,
        consignmentInvoiceError: error,
        consignmentInvoiceLoading: isLoading,
        consignmentInvoiceRefetch : refetch
    };
};

export default useGetOneConsignmentInvoice;
