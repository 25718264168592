import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_SALES_RETURN } from '@utils/constants/trip/trip-sales-return.constants';

export const tripSalesReturnApi = createApi({
    reducerPath: 'tripSalesReturnApi',
    tagTypes: ['TripSalesReturn'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTripSalesReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                trip_sales_invoice_code?: string;
                trip_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                trip_sales_invoice_code,
                trip_sales_return_code,
            }) => {
                const queryParams = {
                    url: TRIP_SALES_RETURN.GET_ALL,
                    params: { page, limit },
                };

                if (customer_first_name) {
                    //@ts-ignore
                    queryParams.params.customer_first_name =
                        customer_first_name;
                }

                if (trip_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.trip_sales_invoice_code =
                        trip_sales_invoice_code;
                }

                if (trip_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.trip_sales_return_code =
                        trip_sales_return_code;
                }

                return queryParams;
            },
        }),
        getAllTripMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                trip_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,

                trip_sales_return_code,
            }) => {
                const queryParams = {
                    url: TRIP_SALES_RETURN.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit },
                };

                if (trip_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.trip_sales_return_code =
                        trip_sales_return_code;
                }

                return queryParams;
            },
        }),
        getTripSalesReturn: builder.query<
            any,
            { trip_sales_return_id: string | number }
        >({
            query: ({ trip_sales_return_id }) =>
                `${TRIP_SALES_RETURN.GET_ONE}${trip_sales_return_id}`,
        }),
        createTripSalesReturn: builder.mutation({
            query: (body) => ({
                url: TRIP_SALES_RETURN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateTripSalesReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${TRIP_SALES_RETURN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        createTripMakePayment: builder.mutation({
            query: ({ body }) => ({
                url: `${TRIP_SALES_RETURN.MAKE_PAYMENT}`,
                method: 'POST',
                body,
            }),
        }),
        deleteTripSalesReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${TRIP_SALES_RETURN.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        deleteTripMakePayment: builder.mutation({
            query: ({ trip_sales_return_payment_made_id, body }) => ({
                url: `${TRIP_SALES_RETURN.DELETE_MAKE_PAYMENT}${trip_sales_return_payment_made_id}`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllTripSalesReturnsQuery,
    useGetTripSalesReturnQuery,
    useCreateTripSalesReturnMutation,
    useUpdateTripSalesReturnMutation,
    useDeleteTripSalesReturnMutation,
    useCreateTripMakePaymentMutation,
    useGetAllTripMakePaymentsQuery,
    useDeleteTripMakePaymentMutation,
} = tripSalesReturnApi;
