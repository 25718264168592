import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_PAYMENT_RECEIVE } from '@utils/constants/trip/trip-payment-receive';
import {SECONDARY_PAYMENT_RECEIVE} from "@utils/constants/secondary-payment-receive.constants";

export const tripPaymentReceiveApi = createApi({
    reducerPath: 'tripPaymentReceiveApi',
    tagTypes: ['TripPaymentReceive'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTripPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                payment_receive_code?:number | string;
                trip_sale_invoice_code?:number | string;
            }
        >({
            query: ({ page = 1, limit = 10 ,payment_receive_code,trip_sale_invoice_code}) => {
                const queryParams = {
                    url: TRIP_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };
    
                if (payment_receive_code) {
                    //@ts-ignore
                    queryParams.params.payment_receive_code =
                        payment_receive_code;
                }
                if (trip_sale_invoice_code) {
                    //@ts-ignore
                    queryParams.params.trip_sale_invoice_code =
                    trip_sale_invoice_code;
                }
                
                return queryParams;
            },

            
        }),
        createTripPaymentReceive: builder.mutation({
            query: (body) => ({
                url: TRIP_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteTripPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${TRIP_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
        // getTripPaymentReceive: builder.mutation({
        //     query: (id) => ({
        //         url: `${TRIP_PAYMENT_RECEIVE.GET_ONE}${id}`,
        //         method: 'GET',
        //     }),
        // }),
        getTripPaymentReceive: builder.query<
            any,
            { id: string | number }
        >({
            query: ({ id }) => {
                return `${TRIP_PAYMENT_RECEIVE.GET_ONE}${id}`;
            },
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllTripPaymentReceivesQuery,
    useCreateTripPaymentReceiveMutation,
    useDeleteTripPaymentReceiveMutation,
    useLazyGetTripPaymentReceiveQuery
} = tripPaymentReceiveApi;
