export const WAREHOUSE_USER_ASSIGN = {
    BASE_PATH: '/stock-and-inventory/allocation-account-user-assign/',
    GET_ALL: '/warehouse-user-assign',
    GET_ONE: '/warehouse-user-assign/edit/',
    GET_DETAIL: 'warehouse-user-assign/detail/',
    CREATE: '/warehouse-user-assign/create',
    UPDATE: '/warehouse-user-assign/update/',
    DELETE: '/warehouse-user-assign/delete/',
    ADD_NEW_TITLE: 'Add New Allocation Account User Assign',
};
