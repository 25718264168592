export const WAREHOUSE = {
    BASE_PATH: '/stock-and-inventory/allocation-account/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Allocation Account',
    LIST_PAGE_TITLE: 'Allocation Account List',
    EDIT_PAGE_TITLE: 'Edit Allocation Account',
    GET_ALL: '/warehouse',
    CREATE: '/warehouse/create',
    UPDATE: '/warehouse/update/',
    DELETE: '/warehouse/delete/',
    GET_ONE : '/warehouse/edit/'
};
