import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DeleteButton from '../../../components/buttons/DeleteButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@states/store';
import {
    addCities,
    addStates,
    addTownships,
    deleteRegionDetail,
    emptyRegionDetail,
} from '@states/common/common.slice';
import {
    addRegions,
    emptyWayDetails,
    removeRegion,
} from '@states/common/common.slice';

const TripDetailListTable = (props: { regionDetail: any[] }) => {
    const { regionDetail } = props;

    const dispatch = useDispatch<AppDispatch>();

    const deleteWayDetail = (toDelete: any) => {
        dispatch(removeRegion(toDelete));
    };

    const columns = [
        {
            name: 'Division/State Name',
            selector: (row: any) => row.state_name,
            center: true,
        },
        {
            name: 'City Name',
            selector: (row: any) => row.city_name,
            center: true,
        },
        {
            name: 'Township Name',
            selector: (row: any) => row.township_name,
            center: true,
        },
        // {
        //     name: 'Action',
        //     center: true,
        //     selector: (row: any) => (
        //         <div
        //             onClick={() => deleteWayDetail(row)}
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center',
        //             }}
        //         >
        //             <DeleteButton id={row.region_id} actionHandler={() => {}} />
        //         </div>
        //     ),
        // },
    ];

    // useEffect(() => {
    //     return () => {
    //         dispatch(addRegions([]));
    //         dispatch(emptyWayDetails());
    //     };
    // }, []);

    useEffect(() => {
        return () => {
            dispatch(addCities([]));
            dispatch(addStates([]));
            dispatch(addTownships([]));
            dispatch(emptyRegionDetail());
        };
    }, []);

    return (
        <>
            {regionDetail.length > 0 ? (
                <div className="p-8 rounded-md bg-zinc-100">
                    <h3 className="mb-6 text-lg font-semibold text-primary-dark">
                        Trip Detail List
                    </h3>
                    <DataTable
                        responsive
                        striped
                        //@ts-ignore
                        columns={columns}
                        //@ts-ignore
                        data={regionDetail}
                    />
                </div>
            ) : null}
        </>
    );
};

export default TripDetailListTable;
