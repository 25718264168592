import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { GOOD_RETURN_NOTE } from '@utils/constants/good-return/good-return-note.constants';

export const goodReturnNoteApi = createApi({
    reducerPath: 'goodReturnNoteApi',
    tagTypes: ['GoodReturnNote'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllGoodReturnNote: builder.query<
            [],
            { page?: number; limit?: number; brand_name?: string }
        >({
            query: ({ page = 1, limit = 10, brand_name }) => {
                const queryParams = {
                    url: GOOD_RETURN_NOTE.GET_ALL,
                    params: { page, limit },
                };

                if (brand_name) {
                    //@ts-ignore
                    queryParams.params.brand_name = brand_name;
                }

                return queryParams;
            },
        }),
        getAllGoodReceiveNotes: builder.query<
            [],
            { page?: number; limit?: number; brand_name?: string }
        >({
            query: ({ page = 1, limit = 10, brand_name }) => {
                const queryParams = {
                    url: GOOD_RETURN_NOTE.GET_ALL_RECEIVE,
                    params: { page, limit },
                };

                if (brand_name) {
                    //@ts-ignore
                    queryParams.params.brand_name = brand_name;
                }

                return queryParams;
            },
        }),
        getGoodReturnNote: builder.query<any, { id: string | number }>({
            query: ({ id }) => {
                return `${GOOD_RETURN_NOTE.GET_ONE}${id}`;
            },
        }),
        getGoodReceiveNote: builder.query<any, { id: string | number }>({
            query: ({ id }) => {
                return `${GOOD_RETURN_NOTE.GET_ONE_RECEIVE}${id}`;
            },
        }),
        findByInvoice: builder.query<any, { id: string | number }>({
            query: ({ id }) => {
                return `${GOOD_RETURN_NOTE.FIND_BY_INVOICE}?invoiceCode=${id}`;
            },
        }),
        createGoodReturnNote: builder.mutation({
            query: (body) => ({
                url: GOOD_RETURN_NOTE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        createGoodReceiveNote: builder.mutation({
            query: (body) => ({
                url: GOOD_RETURN_NOTE.CREATE_RECEIVE,
                method: 'POST',
                body,
            }),
        }),
        updateGoodReturnNote: builder.mutation({
            query: ({ id, body }) => ({
                url: `${GOOD_RETURN_NOTE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        updateGoodReceiveNote: builder.mutation({
            query: ({ id, body }) => ({
                url: `${GOOD_RETURN_NOTE.UPDATE_RECEIVE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteGoodReturnNote: builder.mutation({
            query: (id) => ({
                url: `${GOOD_RETURN_NOTE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
        deleteGoodReceiveNote: builder.mutation({
            query: (id) => ({
                url: `${GOOD_RETURN_NOTE.DELETE_RECEIVE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllGoodReturnNoteQuery,
    useGetAllGoodReceiveNotesQuery,
    useGetGoodReturnNoteQuery,
    useGetGoodReceiveNoteQuery,
    useLazyFindByInvoiceQuery,
    useCreateGoodReturnNoteMutation,
    useCreateGoodReceiveNoteMutation,
    useUpdateGoodReturnNoteMutation,
    useUpdateGoodReceiveNoteMutation,
    useDeleteGoodReturnNoteMutation,
    useDeleteGoodReceiveNoteMutation,
} = goodReturnNoteApi;
