import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PURCHASE_BILL } from '@utils/constants/purchase/purchase-bill.constants'

export const purchaseBillApi = createApi({
    reducerPath: 'purchaseBillApi',
    tagTypes: ['PurchaseBill'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllPurchaseBills: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                primary_sales_invoice_code?: string;
            }
        >({
            query: ({ page = 1, limit = 10, primary_sales_invoice_code }) => {
                const queryParams = {
                    url: PURCHASE_BILL.GET_ALL,
                    params: { page, limit },
                };

                if (primary_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_invoice_code =
                        primary_sales_invoice_code;
                }

                return queryParams;
            },
        }),
        getPurchaseBill: builder.query<
            any,
            { bill_id: string | number }
        >({
            query: ({ bill_id }) => {
                console.log(
                    `${PURCHASE_BILL.GET_ONE}${bill_id}`
                );
                return `${PURCHASE_BILL.GET_ONE}${bill_id}`;
            },
        }),
        createPurchaseBill: builder.mutation({
            query: (body) => ({
                url: PURCHASE_BILL.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePurchaseBill: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PURCHASE_BILL.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePurchaseBill: builder.mutation({
            query: (id) => ({
                url: `${PURCHASE_BILL.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllPurchaseBillsQuery,
    useGetPurchaseBillQuery,
    useCreatePurchaseBillMutation,
    useUpdatePurchaseBillMutation,
    useDeletePurchaseBillMutation,
} = purchaseBillApi;
