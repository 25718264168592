import BackButton from '@components/buttons/BackButton';
import CustomButton from '@components/buttons/CustomButton';
import CustomDateField from '@components/form/CustomDateField';
import CustomInputField from '@components/form/CustomInputField';
import PageWrapper from '@components/layouts/PageWrapper';
import { Toast } from '@helpers/popups/Toast';
import { TPrimaryInvoice } from '@helpers/validation-schemas/primary-sale-order/invoice.schema';

import {ZPaymentReceive,paymentReceiveSchema} from '@helpers/validation-schemas/consignment/payment-receive.schema'
import { zodResolver } from '@hookform/resolvers/zod';
import { addFormValues, resetFormValues } from '@states/common/common.slice';
import { useCreatePrimaryPaymentReceiveMutation } from '@states/primary-sale-order/primary-payment-receive.api';
import {useCreateConsignmentPaymentReceiveMutation} from '@states/consignment/consignment-payment-receive.api'
import {CONSIGNMENT_PAYMENT_RECEIVE} from '@utils/constants/consignment/consignment-payment-receive.constants'
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';

const ConsignmentPaymentReceiveAddPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { formValues } = useSelector((state: RootState) => state.common);
   
    const {
        consignment_invoice_id,
        consignment_invoice_code,
        balance,
        due_date,
        warehouse_id,
        payment_type_id,
        grand_total_amount,
    }: any = location?.state?.carriedFormValues;

    const defaultValues: any = {
        consignment_invoice_id,
        consignment_invoice_code,
        payment_receive_date: new Date().toISOString(),
        payment_receive_amount: 0,
        invoiceData: {
            balance: balance || 0,
            due_date,
            warehouse_id,
            payment_type_id,
            grand_total_amount,
        },
    };

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
        reset,
    } = useForm<ZPaymentReceive>({
        defaultValues,
        resolver: zodResolver(paymentReceiveSchema),
    });

    const [createConsignmentPaymentReceive, { data, isSuccess }] =
    useCreateConsignmentPaymentReceiveMutation();

    useEffect(() => {
        if (isSuccess && data) {
            Toast.fire({
                title: data.message,
                icon: 'success',
            });

            navigate(CONSIGNMENT_PAYMENT_RECEIVE.BASE_PATH, {
                state: {
                    reload: true,
                },
            });
        }
    }, [isSuccess, data, navigate, Toast]);

    useEffect(() => {
        reset(defaultValues);

        dispatch(addFormValues(defaultValues));
    }, [location, reset]);
  

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            dispatch(addFormValues(value));
        });

        return () => {
            subscription.unsubscribe();
            dispatch(resetFormValues());
        };
    }, [watch, dispatch]);

    const onSubmit: SubmitHandler<ZPaymentReceive> = async (data) => {
        try {
            // async stuff here
            const { invoiceData,...rest } = data;
   
            let trimData =   {
                consignment_invoice_id: rest.consignment_invoice_id,
                payment_receive_date: rest.payment_receive_date,
                payment_receive_amount: rest.payment_receive_amount,
                // @ts-ignore
                balance  : invoiceData.balance
             }
    
            await createConsignmentPaymentReceive(trimData);

        } catch (error: any) {
            setError('root', {
                message: error.message,
            });
        }
    };

    return (
        <PageWrapper>
            <BackButton />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
                    Make Payment Receive
                </h3>
                <div className="grid grid-cols-6 gap-x-4 gap-y-8">
                    <input
                        type="hidden"
                        {...register('consignment_invoice_code')}
                    />
                    <CustomInputField
                        disabled
                        label="Invoice Id"
                        errors={errors}
                        name={'consignment_invoice_code'}
                        placeHolder="Enter invoice id"
                        register={register}
                    />
                    <CustomDateField
                        disabled
                        errors={errors}
                        name="invoiceData.due_date"
                        label="Due Date"
                        register={register}
                        //@ts-ignore
                        control={control}
                    />
                    <CustomDateField
                        errors={errors}
                        name="payment_receive_date"
                        label="Payment Receive Date"
                        register={register}
                        //@ts-ignore
                        control={control}
                    />
                    <CustomInputField
                        disabled
                        inputType="number"
                        label="Total Invoice Amount"
                        errors={errors}
                        name={'invoiceData.grand_total_amount'}
                        placeHolder="Enter total invoice amount"
                        register={register}
                    />
                    <CustomInputField
                        disabled
                        inputType="number"
                        label="Balance"
                        errors={errors}
                        name={'invoiceData.balance'}
                        placeHolder="Enter balance"
                        register={register}
                    />
                    <CustomInputField
                        inputType="number"
                        label="Paid Amount"
                        errors={errors}
                        name={'payment_receive_amount'}
                        placeHolder="Enter paid amount"
                        register={register}
                        max={balance}
                        min={1}
                    />
                </div>
                <div className="ml-2 mt-14">
                    <CustomButton
                        isLarge
                        title="Cancel"
                        color="white"
                        textColor="gray.800"
                        className="mr-10"
                        handleClick={() => navigate(-1)}
                    />
                    <CustomButton
                        handleClick={handleSubmit(onSubmit)}
                        isLarge
                        title={isSubmitting ? 'Loading...' : 'Submit'}
                        disabled={isSubmitting}
                        type="submit"
                    />
                </div>
                {errors.root && (
                    <div className="mt-6 text-red-500">
                        {errors.root.message}
                    </div>
                )}
            </form>
        </PageWrapper>
    );
};

export default ConsignmentPaymentReceiveAddPage;
